import type {ReactElement} from 'react';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import type {SelectChangeEvent} from '@mui/material/Select/Select.js';
import Button from '@mui/material/Button/Button.js';
import Card from '@mui/material/Card/Card.js';
import CardActions from '@mui/material/CardActions/CardActions.js';
import CardContent from '@mui/material/CardContent/CardContent.js';
import MenuItem from '@mui/material/MenuItem/MenuItem.js';
import Select from '@mui/material/Select/Select.js';
import Typography from '@mui/material/Typography/Typography.js';

import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Instance, Person} from '@refinio/one.core/lib/recipes.js';
import {getIdObject} from '@refinio/one.core/lib/storage-versioned-objects.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type Authenticator from '@refinio/one.models/lib/models/Authenticator/Authenticator.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type ConnectionsModel from '@refinio/one.models/lib/models/ConnectionsModel.js';
import {getInstancesOfPerson} from '@refinio/one.models/lib/misc/instance.js';
import type {OneInstanceEndpoint} from '@refinio/one.models/lib/recipes/Leute/CommunicationEndpoints.js';
import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import type ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import BackButton from '@/components/backButton/BackButton.js';
import DeleteInstanceModal from './DeleteInstanceModal.js';
import '@/components/card/ContactCard.css';
import '@/root/settings/InstancesSettingsView.css';
import BackupSection from '@/root/settings/instanceId/BackupSection.js';
import './DetailedInstanceSettingsView.css';

type InstanceSettingsDetails = {
    ownerName: string;
    language: string;
    instance: InstanceDetails;
};

type InstanceDetails = {
    id?: SHA256IdHash<Instance>;
    name: string;
    isLocal: string;
    owner: SHA256IdHash<Person>;
};

/**
 *  Detailed View for Settings
 */
export default function DetailedInstanceSettingsView(props: {
    leuteModel: LeuteModel;
    one: Authenticator;
    connectionsModel: ConnectionsModel;
    channelManager: ChannelManager;
}): ReactElement {
    const {i18n} = useTranslation();

    const params = useParams<{personId: SHA256IdHash<Person>}>();
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    const [instanceSettingsDetails, setInstanceSettingsDetails] =
        useState<InstanceSettingsDetails>();
    const [deleteInstanceModal, setDeleteInstanceModal] = useState(false);
    const [language, setLanguage] = useState(i18n.language);
    const navigate = useNavigate();
    const appBarContext = useContext(AppBarContext);
    const isLocalInstance =
        instanceSettingsDetails !== undefined &&
        instanceSettingsDetails.instance.isLocal === 'true';

    useEffect(() => {
        async function fetchInstanceSettingsDetails(personId: SHA256IdHash<Person>): Promise<void> {
            const mainProfile = await props.leuteModel.getMainProfile(personId);
            const ownerName = props.leuteModel.getPersonName(mainProfile.personId);
            const instanceDetails = await getInstanceDetailsForProfile(mainProfile);

            setInstanceSettingsDetails({
                ownerName: ownerName,
                language: i18n.language,
                instance: instanceDetails
            });
            setLanguage(i18n.language);
        }

        if (params.personId) {
            fetchInstanceSettingsDetails(params.personId).catch(() => {
                setNotificationType(NOTIFICATION.Error);
                setNotificationMessage('common.errors.settings.canNotOpenInstance');
                navigate('/settings', {replace: true});
            });
        }
    }, [
        i18n.language,
        navigate,
        params.personId,
        props.leuteModel,
        setNotificationMessage,
        setNotificationType
    ]);

    async function handleLanguageChange(event: SelectChangeEvent) {
        await i18n.changeLanguage(event.target.value);
        setLanguage(event.target.value);
    }

    function onDeleteInstanceButtonPress() {
        setDeleteInstanceModal(true);
    }

    function closeDeleteInstanceModal() {
        setDeleteInstanceModal(false);
    }

    async function handleDeleteInstance(): Promise<void> {
        // @todo safety check in case the logout fails (IDBRequest Error - needs to be investigated in the future)
        try {
            await props.one.logoutAndErase();
        } catch (e) {
            if (
                String(e).includes(
                    "InvalidStateError: Failed to read the 'result' property from 'IDBRequest': The request has not finished."
                )
            ) {
                props.one.authState.triggerEvent('logout_done');
            } else {
                throw e;
            }
        } finally {
            setNotificationType(NOTIFICATION.Success);
            setNotificationMessage('settings.deleteModal.onDeleteNotification');
            window.location.href = '/';
        }
    }

    /**
     * Retrieves the instance details for the given profile
     *
     * Note: This should return a list of instances, not just a single one.
     *
     * @param mainProfile
     */
    async function getInstanceDetailsForProfile(
        mainProfile: ProfileModel
    ): Promise<InstanceDetails> {
        try {
            // Retrieve instances
            const instances = await getInstancesOfPerson(mainProfile.personId);
            if (instances.length === 0) {
                return {
                    name: props.leuteModel.getPersonName(mainProfile.personId),
                    isLocal: 'false',
                    owner: mainProfile.personId
                };
            }

            // Retrieve a local instance if it exists, otherwise retrieve the first of any.
            // Note: we should return all instances, which would make this complicated rule obsolete.
            let instanceId: SHA256IdHash<Instance>;
            let isLocal: boolean;
            const localInstances = instances.filter(instance => instance.local);
            if (localInstances.length > 0) {
                instanceId = localInstances[0].instanceId;
                isLocal = true;
            } else {
                instanceId = instances[0].instanceId;
                isLocal = false;
            }

            return {
                id: instanceId,
                name: (await getIdObject(instanceId)).name,
                isLocal: isLocal.toString(),
                owner: mainProfile.personId
            };
        } catch (e) {
            const endpoint = mainProfile.communicationEndpoints.find(
                aEndpoint => aEndpoint.$type$ === 'OneInstanceEndpoint'
            );
            let instanceId: SHA256IdHash<Instance> | undefined;

            if (endpoint !== undefined) {
                instanceId = (endpoint as OneInstanceEndpoint).instanceId;
            }

            return {
                id: instanceId,
                name: props.leuteModel.getPersonName(mainProfile.personId),
                isLocal: 'false',
                owner: mainProfile.personId
            };
        }
    }

    /**
     * Handler for pressing an instance settings
     */
    function onBackPressed() {
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            title: i18n.t('menu.settings')
        });
        navigate(-1);
    }

    return (
        <div className="instance-settings-wrapper">
            <DeleteInstanceModal
                open={deleteInstanceModal}
                handleDelete={handleDeleteInstance}
                handleClose={closeDeleteInstanceModal}
            />
            <BackButton functionality={onBackPressed} />
            <div className="cnt-title">Instance</div>
            <Card className={'instance-settings cnt-someone-card'} sx={{minWidth: 275}}>
                {instanceSettingsDetails !== undefined ? (
                    <CardContent>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings.ownerName')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.ownerName}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings.isLocal')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.instance.isLocal}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings.instanceOwner')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="p"
                        >
                            {instanceSettingsDetails.instance.owner}
                        </Typography>
                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings.instanceName')}
                        </Typography>
                        <Typography
                            className={'instance-setting-item-margin'}
                            variant="body1"
                            component="div"
                        >
                            {instanceSettingsDetails.instance.name}
                        </Typography>
                        {instanceSettingsDetails.instance.id !== undefined && (
                            <>
                                <Typography sx={{fontSize: 12}} color="text.secondary">
                                    {i18n.t('settings.instanceId')}
                                </Typography>

                                <Typography
                                    className={'instance-setting-item-margin'}
                                    variant="body1"
                                    component="div"
                                >
                                    {instanceSettingsDetails.instance.id}
                                </Typography>
                            </>
                        )}

                        <Typography sx={{fontSize: 12}} color="text.secondary">
                            {i18n.t('settings.gitHash')}
                        </Typography>
                        <div className="commit">
                            <Typography
                                className={'instance-setting-item-margin commit'}
                                variant="body1"
                                component="div"
                            >
                                {'547114520f692b718f684ba8f990a9d17fb856d9'}
                            </Typography>
                            <Button
                                onClick={e => {
                                    e.preventDefault();
                                    window.location.reload();
                                    navigate('/contacts');
                                }}
                                color="primary"
                                className={'update-button'}
                                variant="outlined"
                                size="small"
                            >
                                {i18n.t('buttons.common.update')}
                            </Button>
                        </div>
                        {instanceSettingsDetails.instance.isLocal === 'true' ? (
                            <>
                                <Typography sx={{fontSize: 12}} color="text.secondary">
                                    {i18n.t('common.languages.title')}
                                </Typography>
                                <Select
                                    className={'language-picker'}
                                    variant={'standard'}
                                    value={language}
                                    onChange={handleLanguageChange}
                                    label="Language"
                                >
                                    <MenuItem value={'en'}>
                                        {i18n.t('common.languages.en')}
                                    </MenuItem>
                                    <MenuItem value={'de'}>
                                        {i18n.t('common.languages.de')}
                                    </MenuItem>
                                </Select>
                            </>
                        ) : null}
                    </CardContent>
                ) : (
                    'Loading'
                )}
                {isLocalInstance ? (
                    <CardActions>
                        <Button
                            onClick={onDeleteInstanceButtonPress}
                            color="error"
                            className={'instance-button'}
                            variant="outlined"
                            size="small"
                        >
                            {i18n.t('buttons.common.delete')}
                        </Button>
                    </CardActions>
                ) : null}
            </Card>
            {isLocalInstance && <BackupSection channelManager={props.channelManager} />}
        </div>
    );
}
